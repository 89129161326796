<script setup lang="ts">
const statusRequest = ref<boolean>(false)
const formErrors = ref<string | null>(null)
const validationSchema = useValidation(['url'])

async function submit(body: CommonFormBody) {
  if (body!.url) {
    const headers = { Authorization: ' ' }
    const { error } = await useApi(apiCompanyAdditionRequest, {
      method: 'POST',
      body,
      headers 
    })

    if (error.value) {
      formErrors.value = error.value.data.url[0]
      statusRequest.value = false
    } else {
      formErrors.value = null
      statusRequest.value = true
    }
  }
}
</script>

<template>
  <section
    id="section-shop"
    class="shop"
  >
    <img
      src="/img/home/shop.webp"
      alt="shop"
      width="632"
      class="shop__img"
    />
    <div class="shop__form">
      <h2
        v-if="statusRequest"
        class="shop__title"
      >
        {{ $t('homePage.shopTitleAccess') }}
      </h2>
      <CommonForm
        v-if="!statusRequest"
        :title="$t('homePage.shopTitle')"
        type="text"
        name="url"
        :placeholder="$t('homePage.shopPlaceholder')"
        :buttonName="$t('homePage.shopButton')"
        :formErrors="formErrors"
        :validationSchema="validationSchema"
        alignment="center"
        colorError="var(--c-critical-dark)"
        @submit-form="submit"
      />
    </div>
    <img
      src="/img/home/shop-mobile.webp"
      alt="shop"
      width="632"
      class="shop__img-mobile"
    />
  </section>
</template>

<style lang="scss" scoped>
.shop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;

  @media (width < 1024px) {
    display: flex;
    flex-direction: column;
  }

  &__img {
    aspect-ratio: 1.49;

    @media (width < 700px) {
      display: none;
    }
  }

  &__img-mobile {
    display: none;
    aspect-ratio: 1.39;

    @media (width < 700px) {
      display: block;
      width: 100%;
    }
  }

  &__title {
    color: var(--c-text-primary);
    font-size: clamp(32px, 3.5vw, 48px);
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    margin-block-end: 40px;
  }
  
  &__form {
    max-height: 330px;
    padding: 70px 43px;
    border-radius: var(--border-radius);
    background: var(--c-brand);

    @media (width < 700px) {
      padding: 24px 12px;
    }
  }

  &__text {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin-block-end: 40px;
  }
}
</style>
