<script lang="ts" setup>

const scrollToTarget = () => {
 const el = document.getElementById('section-shop');
 if (el) {
  el.scrollIntoView({ behavior: 'smooth' });
 }
};
</script>

<template>
  <div class="hero__grid-wrapper">
    <div class="left-top">
      <div class="left-top__wrapper">
        <p class="hero__text">{{ $t('homePage.physical.heroText2[0]') }}</p>
      </div>
    </div>

    <div class="middle-top">
      <span class="hero__text">{{ $t('homePage.physical.heroText2[3]') }}</span>
    </div>
    <div class="left-bottom">
      <div class="left-bottom__wrapper">
        <p class="hero__text">{{ $t('homePage.physical.heroText2[1]') }}</p>
      </div>
    </div>

    <div class="right-bottom">
      <div class="right-bottom__wrapper">
        <p class="hero__text">{{ $t('homePage.physical.heroText2[2]') }}</p>
      </div>
    </div>

    <div class="right-top">
      <div class="right-top__wrapper"></div>
    </div>

    <div class="hero__wrapper-content">
      <div class="hero__wrapper-title">
        <h2 class="hero__title">
          {{ $t('homePage.physical.heroTitle2[0]') }}
          {{ $t('homePage.physical.heroTitle2[1]') }}
        </h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle2[2]') }}</h2>
        <h2 class="hero__title">
          {{ $t('homePage.physical.heroTitle2[3]') }}
          {{ $t('homePage.physical.heroTitle2[4]') }}
          {{ $t('homePage.physical.heroTitle2[5]') }}
        </h2>
        <h2 class="hero__title"></h2>
      </div>
      <div class="hero__wrapper-title-768">
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle2[0]') }}</h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle2[1]') }}</h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle2[2]') }}</h2>
        <h2 class="hero__title">
          {{ $t('homePage.physical.heroTitle2[3]') }}
          {{ $t('homePage.physical.heroTitle2[4]') }}
        </h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle2[5]') }}</h2>
      </div>
    </div>
    <div class="hero__wrapper-button">
      <button
        class="common-button hero__button"
        @click='scrollToTarget'
      >
        {{ $t('homePage.offersButton') }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hero {
  &__grid-wrapper {
    border-radius: var(--border-radius);
    background: var(--c-text-primary);
    min-height: 590px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr);
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (width < 768px) {
      min-height: 790px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 140px 1fr 1fr 100px;
      padding-bottom: 24px;
    }
  }
  &__wrapper-content {
    position: relative;
    display: flex;
    grid-column: 4/13;
    grid-row: 2/3;
    flex-direction: column;
    align-items: flex-start;

    @media (width < 768px) {
      grid-column: 2/5;
      width: 100%;
      padding-top: 25px;
      grid-row: 3/4;
      align-items: start;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        width: 33px;
        height: 33px;
        border-radius: var(--border-radius-circle);
        bottom: 43px;
        left: -49px;
        z-index: 10;
        background: var(--c-brand);
      }
    }
  }
  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(32px, 3.5vw, 52px);
    font-style: normal;
    font-weight: 900;
    line-height: 101%;
    text-transform: uppercase;
    color: var(--c-white);

    &:nth-child(2) {
      justify-self: center;
    }
    &:nth-child(4) {
      justify-self: center;
    }
  }
  &__wrapper-button {
    width: 100%;
    display: grid;
    align-self: start;
    grid-row: 3/4;
    grid-column: 5/8;
    grid-template-columns: repeat(8, 1fr);

    @media (width < 1024px) {
      align-self: end;
      grid-row: 2 / 3;
    }

    @media (width < 768px) {
      grid-template-columns: 1fr;
      align-self: end;
      grid-row: 6/7;
      grid-column: 1/13;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  &__text {
    font-size: clamp(13px, 3vw, 16px);
    font-weight: 600;
    line-height: 150%;
    color: var(--c-white);
    text-align: center;
  }
  &__wrapper-title {
    display: grid;
    grid-template-columns: 1fr;

    @media (width < 768px) {
      display: none;
    }
    &-768 {
      display: none;

      @media (width < 768px) {
        display: grid;
        width: 100%;
      }
    }
  }
  &__button {
    background: var(--c-bg-btn);
    border: none;
    grid-column: 2/4;
    height: max-content;
    position: relative;
    width: max-content;
    justify-self: center;
    color: var(--c-text-primary);
    padding: 17px 72px;
    font-size: 14px;
    font-weight: 800;
    text-decoration: none;

    @media (width < 768px) {
      grid-column: span 1;

      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: var(--border-radius-circle);
        top: -17px;
        right: 23px;
        z-index: 10;
        background: var(--c-brand);
      }
    }

    @media (width < 400px) {
      padding: 17px 60px;
    }
  }
}
.left-top {
  padding-top: 58px;
  max-width: 227px;
  width: 100%;
  grid-column: 1/6;
  grid-row: 1 / 2;
  justify-self: center;
  align-self: center;
  transform: rotate(-7.81deg);

  @media (width <768px) {
    grid-row: 2/3;
    grid-column: 4/7;
    justify-self: end;
    margin-right: -25px;
    max-width: 235px;
    transform: rotate(-19.66deg);
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 23px;
    padding-bottom: 20px;
    border-radius: var(--border-radius);
    background: var(--c-bg-hero-medium);

    @media (width < 768px) {
      padding-left: 13px;
      border-radius: 32px 0 0 32px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: var(--border-radius);
      background: var(--c-brand);
      top: -15px;
      left: -44px;
      z-index: 10;
      transform: rotate(16deg);

      @media (width < 1024px) {
        left: -32px;
      }

      @media (width < 768px) {
        width: 21px;
        height: 21px;
        left: 83px;
        top: -90px;
      }
    }
  }
}
.left-bottom {
  position: relative;
  grid-column: 1/5;
  justify-self: center;
  grid-row: 3/4;
  max-width: 214px;
  transform: rotate(16deg);

  @media (width < 768px) {
    grid-row: 4/6;
    align-self: center;
    padding-top: 40px;
    padding-bottom: 95px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: var(--border-radius-circle);
    background: var(--c-brand);
    top: 87px;
    right: 147px;
    z-index: 10;
    transform: rotate(16deg);

    @media (width < 768px) {
      width: 8px;
      height: 8px;
      left: 0;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 12px;
    padding-bottom: 11px;
    border-radius: var(--border-radius);
    background: var(--c-bg-hero-medium);

    &::before {
      content: '';
      position: absolute;
      width: 185px;
      height: 73px;
      background-image: url('/img/hero/hero-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 11px;
      right: -193px;
      z-index: 10;
      transform: rotate(-11deg);

      @media (width < 768px) {
        background-size: contain;
        background-image: url('/img/hero/hero-arrow-768.svg');
        bottom: -41px;
        right: -100px;
        transform: rotate(-16deg);
        height: 92px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 46px;
      height: 46px;
      border-radius: var(--border-radius-circle);
      background: var(--c-brand);
      bottom: -45px;
      left: -23px;
      z-index: 10;

      @media (width < 1024px) {
        width: 25px;
        height: 25px;
        bottom: -45px;
        left: 0;
      }

      @media (width < 768px) {
        width: 15px;
        height: 15px;
        bottom: 104px;
        left: 220px;
      }
    }
  }
}
.right-bottom {
  position: relative;
  max-width: 214px;
  grid-row: 3/4;
  justify-self: center;
  grid-column: 9/13;
  transform: rotate(-4.34deg);

  @media (width < 768px) {
    grid-row: 1/3;
    grid-column: 1/4;
    align-self: center;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 0px;
    border-radius: var(--border-radius);
    background: var(--c-bg-hero-medium);

    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: var(--border-radius-circle);
      background: var(--c-brand);
      top: -33px;
      left: 74px;
      z-index: 10;
    }

    &::after {
      content: '';
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: var(--border-radius-circle);
      background: var(--c-brand);
      top: -49px;
      left: 58px;
      z-index: 10;
    }
  }
}
.right-top {
  position: relative;
  grid-column: 10/13;
  align-self: center;
  width: 123px;
  height: 123px;
  background-image: url('/img/hero/hero-img-key.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  grid-row: 1/2;
  max-width: 244px;
  width: 100%;

  @media (width < 768px) {
    grid-column: 5/7;
  }

  &__wrapper {
    &::before {
      content: '';
      position: absolute;
      width: 33px;
      height: 33px;
      border-radius: var(--border-radius-circle);
      bottom: -2px;
      left: 38px;
      z-index: 10;
      background: var(--c-brand);

      @media (width <768px) {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: var(--border-radius-circle);
      top: 69px;
      left: 31px;
      z-index: 10;
      background: var(--c-brand);

      @media (width <768px) {
        display: none;
      }
    }
  }
}
.middle-top {
  position: relative;
  grid-column: 5/10;
  padding-bottom: 26px;
  align-self: end;
  justify-self: center;
  grid-row: 1/2;
  max-width: 300px;
  width: 100%;

  @media (width < 1024px) {
    justify-self: center;
    grid-column: 5/11;
  }

  @media (width < 768px) {
    grid-row: 2/3;
    grid-column: 2/4;

    justify-self: end;
  }
}
</style>
