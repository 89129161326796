<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
const { t } = useI18n()

definePageMeta({
  layout: 'main',
})

useHead({
  title: t('pageMeta.home'),
})
</script>

<template>
  <div class="home full">
    <HomeHero />
    <HomeReasons />
    <HomeJoin :title="$t('homePage.joinTitle')" />
    <HomeWork />
    <HomeShop />
    <HomeNews />
    <HomeContacts />
  </div>
</template>

<style lang='scss'>
</style>