<script setup>
const data = [
  {
    subtitle: 'reasons.blockTitle[0]',
    description: 'reasons.blockDescription[0]',
    icon: 'svg/home/reasons-1.svg',
  },
  {
    subtitle: 'reasons.blockTitle[1]',
    description: 'reasons.blockDescription[1]',
    icon: 'svg/home/reasons-2.svg',
  },
  {
    subtitle: 'reasons.blockTitle[2]',
    description: 'reasons.blockDescription[2]',
    icon: 'svg/home/reasons-3.svg',
    subDescription: 'reasons.blockSubDescription',
  }
]
</script>

<template>
  <section class="reasons">
    <div class="reasons__title-wrapper">
      <h2 class="reasons__title home__title">
        {{ $t('homePage.reasonsTitle[0]') }}
      </h2>
      <h2 class="reasons__title home__title">
        {{ $t('homePage.reasonsTitle[1]') }}
      </h2>
    </div>
    <div class="reasons__block-wrapper">
      <article
        v-for='({subtitle, description, icon, subDescription}, index) in data'
        :key='index'
        class="reasons__block"
        :class='`reasons__block-${index + 1}`'
      >
        <img
          class='reasons__icon'
          :class='`reasons__icon-${index + 1}`'
          :src="icon"
          alt=""
        >
        <h3 class="reasons__subtitle">
          {{ $t(subtitle) }}
        </h3>
        <h3 class="reasons__subtitle reasons__subtitle-mobile">
          {{ $t(description) }}
        </h3>
        <span
          v-if='subDescription'
          class='reasons__description'
        > {{ $t(subDescription) }} </span>
      </article>
    </div>
  </section>
</template>

<style lang='scss' scoped>
.reasons {
  --size-img: 48px;
  --size-img-3: 78px;
  --radius-indent: 60px;
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-end: var(--radius-indent);
  }

  &__title {
    max-width: 900px;
  }

  &__block-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (width < 700px) {
      grid-template-columns: 1fr;
    }
  }

  &__block {
    display: grid;
    grid-template-rows: 138px 25px auto;
    row-gap: 20px;
    padding: var(--radius-indent) 20px 30px ;
    border-radius: var(--radius-indent);

    @media (width < 700px) {
      padding: 30px 20px 24px;
      grid-template-rows: 78px 25px auto;
      row-gap: 8px;
    }

    &-1 {
      background: var(--c-brand-light);
    }

    &-2 {
      background: var(--c-brand);
    }

    &-3 {
      background: var(--c-bg-special);
    }
  }

  &__icon {
    justify-self: center;
    align-self: center;

    @media (width < 700px) {
      width: var(--size-img);
      height: var(--size-img);
    }

    &-3 {
      @media (width < 700px) {
        width: var(--size-img-3);
        height: var(--size-img-3);
      }
    }
  }

  &__subtitle {
    justify-self: center;
    color: var(--c-text-1);
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    max-width: 310px;

    @media (width < 992px) {
      font-size: 16px;
      font-weight: 600;
      max-width: 205px;
    }

    &-mobile { 
      @media (width < 992px) {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__description {
    display: block;
    color: var(--c-text-1);
    font-size: 12px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;

    @media (width < 992px) {
      color: var(--c-text-secondary);
      font-size: 10px;
    }
  }
}
</style>
