<script setup lang="ts">
const currentIdx = ref(0)
const startX = ref(0)
const endX = ref(0)
const startY = ref(0)
const endY = ref(0)

// const slides = computed(() => [{ idx: 0 }, { idx: 1 }, { idx: 2 }])
const slides = computed(() => [{ idx: 0 }, { idx: 1 }])
const handleTouchStart = (event: any) => {
  startX.value = event.touches[0].clientX
}
const handleTouchMove = (event: any) => {
  endX.value = event.touches[0].clientX
}
const handleTouchEnd = () => {
  const deltaX = endX.value - startX.value
  const deltaY = endY.value - startY.value
  const vertical = 1

  if (Math.abs(deltaY) > vertical) {
    return
  }
  if (Math.abs(deltaX) > 50) {
    if (deltaX < 0) {
      currentIdx.value = (currentIdx.value + 1) % slides.value.length
    } else {
      currentIdx.value =
        (currentIdx.value - 1 + slides.value.length) % slides.value.length
    }
  }
}
const toggleSlide = (index: number) => {
  currentIdx.value = index
}
</script>

<template>
  <section class="hero">
    <div
      v-for="(_, idx) in 2"
      :key="idx"
      class="hero__thumb hero__thumb--small"
      @click.prevent="toggleSlide(idx)"
    >
      <img
        :src="`/img/hero/hero-${idx + 1}.webp`"
        alt="hero"
        class="hero__img"
      />
    </div>

    <div
      class="hero__thumb hero__thumb--big"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div class="hero__breadcrumbs">
        <span
          v-for="(_, idx) in 2"
          :key="idx"
          class="hero__crumbs"
          :class="{ 'is-active': currentIdx === idx }"
        >
        </span>
      </div>
      <Transition
        name="fade"
        mode="out-in"
      >
        <HomeHeroSlideOne v-if="currentIdx === 0" />
        <HomeHeroSlideTwo v-else-if="currentIdx === 1" />
        <!-- <HomeHeroSlideThree v-else-if="currentIdx === 2" /> -->
      </Transition>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.hero {
  --row-gap: 20px;

  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);

  @media (width < 700px) {
    display: flex;
    justify-content: center;
  }

  &__thumb {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    @media (width < 700px) {
      // justify-content: stretch;
    }

    &--small {
      @media (width < 1024px) {
        display: none;
      }
    }

    &--big {
      grid-area: 1 / 2 / 4 / 7;

      @media (width < 1024px) {
        grid-area: 1 / 1 / -1 / -1;
      }

      @media (width < 700px) {
        width: 100%;
        padding: 12px 12px 24px;
      }
    }
  }

  &__breadcrumbs {
    display: none;
    position: absolute;
    top: 25px;
    left: 40px;
    width: calc(100% - 80px);
    z-index: 50;

    @media (width < 700px) {
      display: flex;
      justify-content: center;
      gap: 8px;
    }
  }
  &__crumbs {
    display: block;
    width: 100%;
    height: 2px;
    background: var(--c-borders-secondary);
  }
  &__img {
    height: 100%;
    object-fit: cover;

    @media (width < 1250px) {
      object-fit: fill;
    }

    &--big {
      border-radius: var(--border-radius);
    }
  }
}
.is-active {
  background: var(--c-white);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
