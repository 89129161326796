<script lang="ts" setup>
</script>

<template>
  <div class="hero__grid-wrapper">
    <div class="hero__wrapper-left-top left-top">
      <div class="left-top__wrapper">
        <p class="hero__text">{{ $t('homePage.physical.heroText1[0]') }}</p>
      </div>
    </div>

    <div class="hero__wrapper-right-top right-top">
      <div class="right-top__wrapper">
        <span class="right-top__icon"></span>
        <p class="hero__text">{{ $t('homePage.physical.heroText1[1]') }}</p>
      </div>
    </div>

    <div class="hero__wrapper-left-middle left-middle">
      <div class="left-middle__wrapper">
        <p class="hero__text hero__middle">
          {{ $t('homePage.physical.heroText1[4]') }}
        </p>
      </div>
    </div>

    <div class="hero__wrapper-left-bottom left-bottom">
      <div class="left-bottom__wrapper">
        <p class="hero__text">{{ $t('homePage.physical.heroText1[2]') }}</p>
      </div>
    </div>

    <div class="hero__wrapper-right-bottom right-bottom">
      <div class="right-bottom__wrapper">
        <p class="hero__text">{{ $t('homePage.physical.heroText1[3]') }}</p>
      </div>
    </div>

    <div class="hero__wrapper-content">
      <div class="hero__wrapper-title">
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle1[0]') }}</h2>
        <h2 class="hero__title">
          {{ $t('homePage.physical.heroTitle1[1]') }}
          {{ $t('homePage.physical.heroTitle1[2]') }}
          {{ $t('homePage.physical.heroTitle1[3]') }}
        </h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle1[4]') }}</h2>
      </div>
      <div class="hero__wrapper-title-768">
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle1[0]') }}</h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle1[1]') }}</h2>
        <h2 class="hero__title">{{ $t('homePage.physical.heroTitle1[2]') }}</h2>
        <h2 class="hero__title">
          {{ $t('homePage.physical.heroTitle1[3]') }}
          {{ $t('homePage.physical.heroTitle1[4]') }}
        </h2>
      </div>
    </div>
    <div class="hero__wrapper-button">
      <NuxtLinkLocale
        to="/auth/registration-customer"
        class="common-button hero__button"
      >
        {{ $t('homePage.joinButton') }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hero {
  &__grid-wrapper {
    border-radius: var(--border-radius);
    background: var(--c-bg-btn);
    min-height: 590px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr);
    overflow: hidden;

    @media (width < 768px) {
      min-height: 790px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr 100px;
      padding-bottom: 24px;
    }
  }
  &__wrapper-title {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    @media (width < 768px) {
      display: none;
    }
  }
  &__wrapper-title-768 {
    display: none;

    @media (width < 768px) {
      display: grid;
    }

    & h2:nth-child(4) {
      justify-self: center;
    }
  }
  &__wrapper-content {
    display: flex;
    grid-column: 4/13;
    grid-row: 2/3;
    flex-direction: column;
    align-items: flex-start;

    @media (width < 768px) {
      grid-column: 1/7;
      padding-left: 22px;
      padding-top: 25px;
      grid-row: 3/4;
      align-items: center;
      justify-content: center;
    }
  }
  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(32px, 4vw, 52px);
    font-style: normal;
    font-weight: 900;
    line-height: 101%;
    text-transform: uppercase;

    &:nth-child(2) {
      justify-self: center;
    }
  }
  &__text {
    font-size: clamp(13px, 3vw, 16px);
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    color: var(--c-text-primary);
  }
  &__middle {
    @media (width < 768px) {
      color: transparent;
    }
  }
  &__wrapper-button {
    width: 100%;
    display: grid;
    align-self: start;
    grid-row: 3/4;
    grid-column: 5/8;
    grid-template-columns: repeat(8, 1fr);

    @media (width < 768px) {
      grid-template-columns: 1fr;
      align-self: end;
      grid-row: 6/7;
      grid-column: 1/7;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  &__button {
    background: var(--c-white);
    border: none;
    grid-column: 2/4;
    height: max-content;
    position: relative;
    width: max-content;
    justify-self: center;
    color: var(--c-text-primary);
    padding: 17px 44px;
    font-size: 14px;
    font-weight: 800;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 53%;
      left: 78%;
      width: 136px;
      height: 136px;
      background-image: url('/img/hero/hero-icon-5.webp');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @media (width < 768px) {
        display: none;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: -85%;
      right: -39%;
      width: 33px;
      height: 33px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);

      @media (width < 768px) {
        display: none;
      }
    }

    @media (width < 1024px) {
      margin-bottom: 5%;
    }

    @media (width < 768px) {
      grid-column: 1/4;
      background: var(--c-white);
      color: var(--c-text-primary);
      text-align: center;
      margin-bottom: 0;
    }
  }
}
.left-top {
  padding-top: 58px;
  max-width: 227px;
  width: 100%;
  grid-column: 1/5;
  justify-self: center;
  transform: rotate(-4.93deg);

  @media (width < 768px) {
    grid-column: 1/4;
    grid-row: 1/3;
    align-self: center;
    justify-self: start;
    padding-top: 0;
    max-width: 100%;
    margin-left: -5px;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 15%;
    right: -35%;
    width: 136px;
    height: 136px;
    background-image: url('/img/hero/hero-icon-1.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (width < 768px) {
      top: 118%;
      right: 20%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 28%;
    left: -11px;
    width: 33px;
    height: 33px;
    border-radius: var(--border-radius-circle);
    background-color: var(--c-white);

    @media (width < 768px) {
      top: 75%;
      left: 92%;
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 23px;
    border-radius: var(--border-radius);
    background: var(--c-accent);

    @media (width < 768px) {
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 0 32px 32px 0;
    }
  }
}
.right-top {
  position: relative;
  grid-column: 9/13;
  padding-top: 17%;
  grid-row: 1/3;
  max-width: 244px;
  width: 100%;
  transform: rotate(-9.53deg);

  @media (width < 768px) {
    grid-row: 4/6;
    max-width: 180px;
    grid-column: 1/5;
    justify-self: center;
    align-self: start;
    transform: rotate(14.37deg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 21%;
    left: -6%;
    width: 30px;
    height: 30px;
    border-radius: var(--border-radius-circle);
    background-color: var(--c-white);

    @media (width < 768px) {
      top: 9px;
      left: 101px;
      width: 15px;
      height: 15px;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 35px;
    padding-right: 27px;
    padding-top: 32px;
    padding-bottom: 16px;
    border-radius: var(--border-radius);
    background: var(--c-accent);

    @media (width < 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 16%;
      left: -12%;
      width: 15px;
      height: 15px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);

      @media (width < 768px) {
        top: 92px;
        left: -4%;
        width: 8px;
        height: 8px;
      }
    }
  }

  &__icon {
    position: absolute;
    z-index: 10;
    top: -40%;
    right: 30%;
    width: 85px;
    height: 85px;
    background-image: url('/img/hero/hero-icon-2.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(8.36deg);

    @media (width < 768px) {
      transform: rotate(-88.31deg);
      top: -46px;
    }
  }
}
.left-bottom {
  position: relative;
  grid-column: 1/5;
  justify-self: center;
  grid-row: 3/4;
  max-width: 214px;
  transform: rotate(9.3deg);

  @media (width < 768px) {
    max-width: 175px;
    grid-row: 1/3;
    grid-column: 3/7;
    align-self: end;
    justify-self: end;

    &::after {
      content: '';
      position: absolute;
      top: -112px;
      right: 108px;
      width: 11px;
      height: 11px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 23px;
    padding-bottom: 10px;
    border-radius: var(--border-radius);
    background: var(--c-accent);

    &::before {
      content: '';
      position: absolute;
      top: -35px;
      right: 46px;
      width: 11px;
      height: 11px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);
      @media (width < 768px) {
        right: 85px;
        top: -105px;
        width: 15px;
        height: 15px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      top: -88px;
      right: 103px;
      width: 114px;
      height: 114px;
      background-image: url('/img/hero/hero-icon-3.webp');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: rotate(-12.05deg);

      @media (width < 768px) {
        background-image: url('/img/hero/hero-icon-5.webp');
        transform: rotate(18.39deg);
        top: -90px;
        right: 27px;
      }
    }
  }
}
.right-bottom {
  position: relative;
  max-width: 214px;
  grid-row: 3/4;
  justify-self: center;
  grid-column: 9/13;
  transform: rotate(9.3deg);

  @media (width < 768px) {
    grid-row: 4/6;
    grid-column: 5/7;
    transform: rotate(-19.66deg);
    align-self: end;
    padding-bottom: 40px;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 23px;
    padding-bottom: 20px;
    border-radius: var(--border-radius);
    background: var(--c-accent);

    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 12px;
      right: -35px;
      width: 123px;
      height: 123px;
      background-image: url('/img/hero/hero-icon-4.webp');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: rotate(-12.05deg);

      @media (width < 1024px) {
        top: 42px;
        right: -16px;
      }

      @media (width < 768px) {
        top: 27px;
        transform: translateY(-130%);
        right: -17px;
      }
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -25px;
      right: -23px;
      width: 20px;
      height: 20px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);

      @media (width < 1024px) {
        right: -5px;
        bottom: -53px;
      }

      @media (width < 768px) {
        bottom: -33%;
        right: 51%;
        width: 8px;
        height: 8px;
      }
    }
  }
}
.left-middle {
  position: relative;
  align-self: center;
  padding-bottom: 30px;
  justify-self: center;
  grid-row: 2/3;
  grid-column: 1/5;
  max-width: 214px;
  transform: rotate(-6.38deg);

  @media (width < 1024px) {
    display: none;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 10px;
    padding-right: 75px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: var(--border-radius);
    background: var(--c-accent);

    &::after {
      content: '';
      position: absolute;
      top: -27px;
      left: 17px;
      width: 15px;
      height: 15px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);
    }
    &::before {
      content: '';
      position: absolute;
      top: -24px;
      left: -15px;
      width: 11px;
      height: 11px;
      border-radius: var(--border-radius-circle);
      background-color: var(--c-white);
    }
  }
}
</style>
