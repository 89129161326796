import { NuxtLinkLocale } from '../../.nuxt/components';
<script setup lang='ts'>
const { locale } = useI18n()
const newsStore = useNewsStore()
const url = ref(`${apiNews}news/?fields=*&locale=${locale.value}&limit=3&order=-date`)

await useAsyncData('news', () => newsStore.fetchNews(url.value))
const listNews = computed(() => newsStore.news)
</script>

<template>
  <section class="news">
    <h2 class="news__title home__title">
      {{ $t('homePage.newsTitle') }}
    </h2>
    <div class="news__cards">
      <template
        v-for="card in listNews"
        :key="card?.gallery_images[0]?.id"
      >
        <NuxtLinkLocale
          :to="`/news/${card.meta.slug}`" 
          class='card__link'
        >
          <article class="card">
            <div class="card__thumb">
              <img 
                v-if="card?.gallery_images.length"
                :src="card?.gallery_images[0]?.image.meta.download_url" 
                width="415"
                height="305"
                class="card__img"
                :alt="card?.gallery_images[0]?.image.title"
              >
              <div 
                v-else  
                class="card_img"
              ></div>
              <!-- <img 
                :src="card.gallery_images[1].image.meta.download_url" 
                class="card__img-mobile"
              > -->
            </div>
            <span class="card__date">{{ getFormattingDate(locale, card.date, 'PPP') }}</span>
            <h4 class="card__title">{{ card.title }}</h4>
            <p class="card__text">{{  card.intro }}</p>
          </article>
        </NuxtLinkLocale>
      </template>
    </div>
    <div class="news__link-wrapper">
      <NuxtLinkLocale 
        to="/news" 
        class="news__link"
      >
        <img 
          src="/svg/home/arrow.svg"
          alt="arrow"
          class="news__arrow"
        >
        <span class="news__link-name">{{ $t('homePage.newsButtonName') }}</span>
      </NuxtLinkLocale>
    </div>
  </section>
</template>

<style lang='scss' scoped>
.news {
--border-radius: 32px;
  &__title {
    margin-block-end: 60px;

    @media (width < 700px) {
      margin-block-end: 40px;
    }  
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc((100% - 48px) / 3), 415px));
    gap: 24px;

    @media (width < 1350px) {
      grid-template-columns: repeat(auto-fit, minmax(calc((100% - 24px) / 2), 1fr));
      margin-block-end: 40px;
    }

    @media (width < 700px) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      margin-block-end: 40px;
    }  
  }

  &__link-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    display: block;
    width: 77px;
    height: 50px;
    border-radius: 3px;
    background: var(--c-bg);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (width < 700px) {
      background: var(--c-brand);
      width: 100%;
      border-radius: 40px;
      text-decoration: none;
    }  
  }

  &__link-name {
    display: none;

    @media (width < 700px) {
      display: block;
      color: var(--c-white);
      font-weight: 600;
    }  
  }

  &__arrow {
    @media (width < 700px) {
      display: none;
    }  
  }
}

.card {
  display: grid;
  grid-template-rows: auto 1fr 2fr 2fr;

  @media (width < 700px) {
    margin-block-end: 15px;
    grid-template-rows: auto;
  }

  &__link {
    text-decoration: none;
    color: inherit
  }

  &__thumb {
    width: 100%;
    aspect-ratio: 1.36;
    margin-block-end: 36px;

    @media (width < 1024px) {
      aspect-ratio: 1.95;
    }

    @media (width < 700px) {
      margin-block-end: 8px;
    }  
  }

  &__img {
    width: 100%;
    height: 100%;
    // aspect-ratio: 1.36;
    object-fit: cover;
    border-radius: var(--border-radius);

    // @media (width < 700px) {
    //   display: none;
    // }  
  }

  &__img-mobile {
    display: none;
    aspect-ratio: 1.95;

    @media (width < 700px) {
      display: block;
      width: 100%;
    }  
  }


  &__date {
    margin-block-end: 12px;
    font-size: 14px;
    font-weight: 600;
    color: var(--c-text-light);

    @media (width < 700px) {
      font-size: 10px;
    }  
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-block-end: 12px;

    @media (width < 700px) {
      font-size: 14px;
      font-weight: 600;
      margin-block-end: 8px;
    }  
  }

  &__text {
    font-size: 14px;
    color: var(--c-text-medium);
  }
}
</style>